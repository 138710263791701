/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  FluentProvider,
  teamsLightTheme,
  teamsDarkTheme,
  teamsHighContrastTheme,
  Label,
  Input,
  RadioGroup,
  Radio,
  Spinner,
  Tooltip,
  Checkbox,
  Link
} from "@fluentui/react-components";
import { app, pages } from "@microsoft/teams-js";
import { Info16Regular } from "@fluentui/react-icons";
import LocalizationService from "../common/services/LocalizationService/LocalizationService";
import { AppConstants } from "../common/Constants/AppConstants";

export interface IConfigProps {}

export const Configuration: React.FunctionComponent<IConfigProps> = (props) => {  
  const [subscriptionKey, setSubscriptionKey] = useState<string>('');
  const [sharedMode, setSharedMode] = useState<boolean>(true);
  const [freeTrialMode, setFreeTrialMode] = useState<boolean>(false);
  const [themeName, setThemeName] = useState<string>('default');
  const [localization, setLocalization] = useState<IRTCresources>(undefined);
  const [alreadyMounted, setAlreadyMounted] = useState<boolean>(false);

  const componentDidMount = async () => {
    await app.initialize();

    const teamsContext = await app.getContext();

    setThemeName(teamsContext.app.theme);

    const configValue = await pages.getConfig();

    const loc = await LocalizationService.loadLocale(teamsContext.app.locale);
    setLocalization(loc);

    if(configValue.entityId){
      const entityId = configValue.entityId;
      const entityIdParts = entityId.split(':');

      if(entityIdParts.length >= 2){
        setSubscriptionKey(entityIdParts[0]);
        setSharedMode(entityIdParts[1] === 'true');

        if(entityIdParts.length > 2){
          setFreeTrialMode(entityIdParts[2] === 'true');
        }
      }
    }    
  };

  const save = async () => {
    if(!alreadyMounted){
      await app.initialize();
    }

    if((subscriptionKey && subscriptionKey.length > 0) || freeTrialMode) {
      pages.config.setValidityState(true);

      pages.config.registerOnSaveHandler((saveEvent) => {
        const baseUrl = `https://${window.location.hostname}:${window.location.port}`;

        const configPromise = pages.config.setConfig({
            websiteUrl: `${baseUrl}/index.html#/tab`,
            contentUrl: `${baseUrl}/index.html#/tab`,
            entityId: `${subscriptionKey}:${sharedMode}:${freeTrialMode}`,
        });
        configPromise.then((result) => {saveEvent.notifySuccess()}).catch((error) => {saveEvent.notifyFailure("failure message")});
      });
    }else{
      pages.config.setValidityState(false);
    }
  };

  useEffect(() => {
    if(localization !== undefined){
      setAlreadyMounted(true);
    }
  }, [localization]);

  useEffect(() => {
    save();
  }, [subscriptionKey, sharedMode, freeTrialMode]);

  useEffect(() => {    
    componentDidMount();
  }, []);
  
  return (
    <FluentProvider
      theme={
        themeName === "dark"
          ? teamsDarkTheme
          : themeName === "contrast"
          ? teamsHighContrastTheme
          : {
              ...teamsLightTheme
            }
      }
    >
      {!alreadyMounted && <Spinner />}
      {alreadyMounted && 
      <>
        <Label style={{fontSize: 20, fontWeight: 600}}>{localization.CommonStrings.PropertyPaneGroupSettings}</Label>
        <br /><br />
        <Label>{localization.PropertyPaneStrings.header}</Label>
        <br /><br />
        <Label style={{fontWeight: 600}}>{localization.PropertyPaneStrings.subscriptionKey}</Label>
        <br />
        <Input type='text' style={{width: '95%', marginTop: 5}} value={subscriptionKey} onChange={(ev, data) => { setSubscriptionKey(data.value); setSharedMode(data.value.trim().length > 0 ? true : false); setFreeTrialMode(data.value.trim().length === 0 ? true : false); }} />
        <br />
        <Checkbox style={{left: -7, top: 8, display: !subscriptionKey || subscriptionKey.length === 0 ? 'inline-flex' : 'none'}} checked={freeTrialMode} onChange={(_, data) => { setFreeTrialMode(data.checked as boolean); setSharedMode(false); }} label={localization.PropertyPaneStrings.freeTrialDescription} />
        <br /><br />
        <Label style={{fontWeight: 600}}>{localization.PropertyPaneStrings.modeSelector}</Label>
        <br />
        <RadioGroup disabled={freeTrialMode || !subscriptionKey || subscriptionKey.length === 0} value={sharedMode ? 'groupSpecific' : 'userSpecific'} onChange={(_, data) => setSharedMode(data.value !== 'userSpecific')}>
          <div><Radio value="userSpecific" label={localization.PropertyPaneStrings.individualMode} /><Tooltip withArrow positioning='after' content={localization.WebParts.Tooltips.individualModeDescription} relationship="label"><Info16Regular /></Tooltip></div>
          <div><Radio value="groupSpecific" label={localization.PropertyPaneStrings.groupMode} /><Tooltip withArrow positioning='after' content={localization.WebParts.Tooltips.groupModeDescription} relationship="label"><Info16Regular /></Tooltip></div>
        </RadioGroup>
        <br />
        <Label style={{position: 'absolute', bottom: 0}}>{localization.PropertyPaneStrings.contactUsP1}<Link inline href={`mailto:${AppConstants.runthecloudemail}?subject=${localization.PropertyPaneStrings.licenseEmailSubject}`}>{AppConstants.runthecloudemail}</Link>{localization.PropertyPaneStrings.contactUsP2}</Label>
      </>}
    </FluentProvider>
  );
}